import MapboxCircle from 'mapbox-gl-circle-forked'
import {
  earthRadius,
  featureCollection,
  lineString,
  Feature,
  point,
  polygon,
  Polygon,
  multiLineString,
  MultiLineString
} from '@turf/helpers'
import destination from '@turf/destination'
import turfTransformRotate from '@turf/transform-rotate'
import { getArrowheadWinds } from '@/components/map/commons/helper'
import { WeatherEvent, WeatherEventStation } from '@/store/event/definitions'
import colors from '@/styles/colors'
import { WeatherStationData } from '@/store/weatherstation/definitions'

function translateCoordinates (latlng, heading, distance) {
  heading = (heading + 360) % 360
  const rad = Math.PI / 180
  const radInv = 180 / Math.PI
  const R = 6378137 // approximation of Earth's radius
  const lon1 = latlng[0] * rad
  const lat1 = latlng[1] * rad
  const rheading = heading * rad
  const sinLat1 = Math.sin(lat1)
  const cosLat1 = Math.cos(lat1)
  const cosDistR = Math.cos(distance / R)
  const sinDistR = Math.sin(distance / R)
  const lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 *
                          sinDistR * Math.cos(rheading))
  let lon2 = lon1 + Math.atan2(Math.sin(rheading) * sinDistR *
                              cosLat1, cosDistR - sinLat1 * Math.sin(lat2))
  lon2 = lon2 * radInv
  lon2 = lon2 > 180 ? lon2 - 360 : lon2 < -180 ? lon2 + 360 : lon2
  return [lon2, lat2 * radInv]
}

/**
 * Returns an array of features of points
 * Each point is a measure in km to display on an X axes
 *
 * @param {array} center
 * @param {number} numberOfCircles
 * @returns
 */
function getXMeasuresFeatures (center, radius, numberOfCircles) {
  const features = []
  for (let i = 0; i < numberOfCircles; i++) {
    features.push({
      type: 'Feature',
      properties: {
        title: radius * i / 1000 + ' kms',
        alt: radius * i / 1000 + ' kms'
      },
      geometry: {
        type: 'Point',
        coordinates: translateCoordinates(center, 270, radius * i)
      }
    })
  }
  return features
}

/**
 * Returns an array of features
 * of 2 lines : X and Y axes
 *
 * @param {array} center
 */
function getMapAxesFeatures (center) {
  return [
  // X
    {
      type: 'Feature',
      properties: {
        'line-width': 2
      },
      geometry: {
        type: 'LineString',
        coordinates: [[center[0] + 4, center[1]], [center[0] - 4, center[1]]]
      }
    },
    // Y
    {
      type: 'Feature',
      properties: {
        'line-width': 2
      },
      geometry: {
        type: 'LineString',
        coordinates: [[center[0], center[1] - 4], [center[0], center[1] + 4]]
      }
    }
  ]
}

function getTargetCirclesFeatures (center, radius, numberOfCircles, mapDark = false) {
  const features = []
  for (let i = 1; i <= numberOfCircles; i++) {
    // Create circle
    const circle = new MapboxCircle(center, radius * i, {
      fillColor: 'transparent',
      strokeColor: mapDark ? '#dddddd' : '#777777',
      strokeWeight: 1
    })
    features.push({
      type: 'Feature',
      properties: {
        type: 'circle',
        id: i,
        'line-width': 1,
        radius: 0
      },
      geometry: circle._circle.geometry
    })
  }
  return features
}

/**
 * Returns a Geojson of a target draw
 * The target includes 3 parts : circles, axes and measures (in km)
 *
 * @param {array} center
 * @param {number} radius
 * @param {number} numberOfCircles
 */
export const getTargetGeojson = (center, radius, numberOfCircles, mapDark = false) => ({
  type: 'FeatureCollection',
  features: [
    ...getTargetCirclesFeatures(center, radius, numberOfCircles, mapDark),
    ...getMapAxesFeatures(center),
    ...getXMeasuresFeatures(center, radius, numberOfCircles)
  ]
})

/**
 * Gets the rain path.
 * @param {array} centerXY: Target of the rain path arrow: [lon, lat] in degrees.
 * @param {number} direction: Rotation (along the vertical axis), from North in decimal degrees, negative clockwise.
 *   E.g.: 0 means facing norht, 90 means facing west.
 * @param {number} distanceInMeters: Distance traveled by raincells in a time unit, in meters.
 *   The time unit is defined by the caller of the function.
 * @param {number} numberOfSteps: Number of steps on the rainpath.
 *
 * @return {Object} The rainpath layer, in the given direction.
 *   Its format matches a FeatureCollection GeoJson.
 *   Provided by @turf/helpers.FeatureCollection
 */
export function getRainpathGeoJson (centerXY, direction, distanceInMeters, numberOfSteps) {
  const rainpathGeoJson = featureCollection([])

  // If given distance is invalid, return an empty rain path.
  if (isNaN(distanceInMeters)) {
    return rainpathGeoJson
  }

  // Note: The arrow gets built facing north. We'll rotate the whole thing at the end.

  // Compute the distance in degrees as all coordinates are based on degrees.
  // See http://stackoverflow.com/questions/7477003/calculating-new-longtitude-latitude-from-old-n-meters
  const distanceInDegrees = distanceInMeters / earthRadius * (180 / Math.PI)

  // Arrow shaft.
  const arrowLengthInDegrees = distanceInDegrees * numberOfSteps
  const arrowShaft = lineString([
    centerXY,
    [centerXY[0], centerXY[1] - arrowLengthInDegrees]
  ])

  // Arrow head.
  // const arrowTip = centerXY
  const arrowHeadWidthInDegrees = distanceInDegrees / 4
  // const arrowHeadHeightInDegrees = distanceInDegrees / 3
  // const arrowHeadLeftEnd = [
  //   arrowTip[0] - (arrowHeadWidthInDegrees / 2),
  //   arrowTip[1] - arrowHeadHeightInDegrees
  // ]
  // const arrowHeadRightEnd = [
  //   arrowTip[0] + (arrowHeadWidthInDegrees / 2),
  //   arrowTip[1] - arrowHeadHeightInDegrees
  // ]
  const [left, right] = getArrowheadWinds(centerXY, 180, 0.15) // size of the head is in km by default

  const hatCoords = [[
    left,
    centerXY,
    right,
    left
  ]]
  const arrowHead = polygon(hatCoords, { name: 'rainpath-head' })

  // Arrow steps.
  const arrowSteps = []
  const arrowStepWidthInDegrees = arrowHeadWidthInDegrees
  for (let i = 1; i <= numberOfSteps; i++) {
    const stepPosYInDegrees = centerXY[1] - (distanceInDegrees * i)
    const divisionBase = (i === numberOfSteps || i === numberOfSteps / 2) ? 0.75 : 1.5
    const label = Math.trunc(60 * i / numberOfSteps) + '\''

    arrowSteps.push(lineString([
      [centerXY[0] - (arrowStepWidthInDegrees / divisionBase), stepPosYInDegrees],
      [centerXY[0] + (arrowStepWidthInDegrees / divisionBase), stepPosYInDegrees]
    ]))
    rainpathGeoJson.features.push({
      type: 'Feature',
      properties: {
        title: label,
        alt: label
      },
      geometry: {
        type: 'Point',
        coordinates: [centerXY[0], stepPosYInDegrees]
      }
    })
  }

  // Build feature.
  rainpathGeoJson.features.push(arrowShaft, arrowHead)
  for (let i = 0; i < arrowSteps.length; i++) {
    const step = arrowSteps[i]

    rainpathGeoJson.features.push(step)
  }

  // Return the arrow, after applying the right rotation to it.
  return turfTransformRotate(rainpathGeoJson, direction, { pivot: centerXY })
}

/**
 * Returns the geojson for the rain path layer.
 *
 * @export
 * @param {Array} center: Rain path target (where the rain path arrow will point to).
 *   Position formatted as [lon ,lat] in degrees.
 * @param {Number} speed: Wind speed.
 * @param {Number} direction: Rotation (along the vertical axis), from North in decimal degrees, negative clockwise.
 *   E.g.: 0 means facing norht, 90 means facing west.
 */
export function getRainpathSource (center, speed, direction) {
  // Distance traveled by raincells in 5 minutes.
  const distanceTraveledByRaincells = speed * 1000 / 12

  return {
    type: 'geojson',
    data: getRainpathGeoJson(
      center,
      direction,
      distanceTraveledByRaincells,
      12
    )
  }
}

const defaultMapConfig = {
  MAP_TARGET_CIRCLE_INTERVAL: 2500,
  MAP_TARGET_CIRCLE_ITERATION: 42,
  // COLOR_EVENT: 'var(--color-gold)',
  COLOR_EVENT: '#b9a26c',
  COLOR_OVER_LIMIT: colors['color-red'],
  COLOR_TARGET_LIGHT_MODE: '#777777',
  COLOR_TARGET_DARK_MODE: '#dddddd',
  // RAIN_PATH_COLOR: '#DD66D8',
  // RAIN_PATH_COLOR: '#4F05F2',
  RAIN_PATH_COLOR: '#D63333',
  RAIN_PATH_LINE_MIN_WIDTH: 2,
  RAIN_PATH_LINE_MAX_WIDTH: 6
}

function getWindLayerProperties (
  id: string,
  source: string,
  type: string,
  filters: [string, string, string]
) {
  const defaultProps = {
    id,
    source,
    filters,
    type
  }
  let layerProps = {}
  if (type === 'line') {
    layerProps = {
      ...defaultProps,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-width': 6,
        'line-color': ['get', 'color']
      }
    }
  }
  if (type === 'fill') {
    layerProps = {
      ...defaultProps,
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': 1
      }
    }
  }
  return layerProps
}

/**
 * Create the map style
 *
 * @param event
 * @param {array} center
 * @param stationsOverLimit
 * @param {object} [config=defaultMapConfig]
 * @param {string} mapBackground
 */
export function getMapStyle (
  event: WeatherEvent,
  center,
  stationsOverLimit: [WeatherEventStation],
  config = defaultMapConfig,
  mapBackground: 'light' | 'dark' | 'shadedrelief' | 'satellite' = 'light',
  mapDark = false
) {
  // we create all the layers for the wind
  // 4 layers per station
  const windLayers = []
  const tiles = mapBackground === 'satellite' ? 'satellite/{z}/{x}/{y}.jpg' : `${mapBackground}/{z}/{x}/{y}.png`
  event.stations.forEach((station: WeatherEventStation, index: number) => {
    windLayers.push(
      getWindLayerProperties(
        `gust-arrow-layer-${index}`,
        'source-wind-gust',
        'line',
        ['==', '$name', `gust-arrow-${index}`]
      )
    )
    windLayers.push(
      getWindLayerProperties(
        `gust-head-layer-${index}`,
        'source-wind-gust',
        'fill',
        ['==', '$name', `gust-head-${index}`]
      )
    )
    windLayers.push(
      getWindLayerProperties(
        `wind-arrow-layer-${index}`,
        'source-wind-speed',
        'line',
        ['==', '$name', `wind-arrow-${index}`]
      )
    )
    windLayers.push(
      getWindLayerProperties(
        `wind-head-layer-${index}`,
        'source-wind-speed',
        'fill',
        ['==', '$name', `wind-head-${index}`]
      )
    )
  })

  return {
    version: 8,
    sources: {
    // Source for Radar images  (tiles mode).
      'source-raster-osm': {
        type: 'raster',
        tileSize: 256,
        maxzoom: 15,
        tiles: [
          `${TILES_OSM_URL}${event.code}/${tiles}`
        ]
      },
      // Source for race circuit
      'source-event-track': {
        type: 'geojson',
        data: event.geojson
      },
      // Source for target draw
      'source-event-target': {
        type: 'geojson',
        data: getTargetGeojson(
          center,
          config.MAP_TARGET_CIRCLE_INTERVAL,
          config.MAP_TARGET_CIRCLE_ITERATION,
          mapDark
        )
      },
      // Source for the rain path
      'source-rainpath': {
        type: 'geojson',
        data: null
      },
      // Source for stations
      'source-stations': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: event.stations
        }
      },
      // Source for stations
      'source-stations-over-limit': {
        type: 'geojson',
        data: stationsOverLimit
          ? {
            type: 'FeatureCollection',
            features: stationsOverLimit
          }
          : null
      },
      // Source for wind speed
      'source-wind-speed': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      // Source for wind gusts
      'source-wind-gust': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    },
    layers: [
      // Layer for radar images (tiles mode).
      {
        id: 'layer-raster-osm',
        type: 'raster',
        source: 'source-raster-osm',
        paint: {
          'raster-fade-duration': 0,
          'raster-resampling': 'nearest'
        }
      },
      // Layer for target (line)
      {
        id: 'layer-event-target-line',
        type: 'line',
        source: 'source-event-target',
        paint: {
          'line-color': mapDark ? config.COLOR_TARGET_DARK_MODE : config.COLOR_TARGET_LIGHT_MODE,
          'line-width': ['get', 'line-width'],
          'line-opacity': [
            'interpolate',
            ['exponential', 0],
            ['zoom'],
            9, // Maximum zoom level, show every 6 circles
            ['match', ['%', ['number', ['get', 'id'], 0], 6], 0, 1, 0],
            10, // Medium zoom level, show every 2 circles
            ['match', ['%', ['number', ['get', 'id'], 0], 2], 0, 1, 0],
            12,
            1
          ]
        }
      },
      // Layer for km target (text)
      {
        id: 'layer-event-target-symbol',
        type: 'symbol',
        source: 'source-event-target',
        paint: {
          'text-color': mapDark ? config.COLOR_TARGET_DARK_MODE : config.COLOR_TARGET_LIGHT_MODE,
          'text-halo-color': '#fff',
          'text-halo-width': 1
        },
        layout: {
          'text-field': '{title}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [2, -0.4],
          'text-anchor': 'bottom'
        }
      },
      // Layer for race circuit
      {
        id: 'layer-event-track',
        type: 'line',
        source: 'source-event-track',
        paint: {
          'line-color': config.COLOR_EVENT,
          'line-width': 3
        }
      },
      // Layers for wind speed & gusts
      ...windLayers,
      // Layer for stations (background)
      {
        id: 'layer-stations-circle',
        type: 'circle',
        source: 'source-stations',
        paint: {
          'circle-color': config.COLOR_EVENT,
          'circle-radius': 12,
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2
        }
      },
      // Layer for stations over limit (background)
      {
        id: 'layer-stations-circle-over-limit',
        type: 'circle',
        source: 'source-stations-over-limit',
        paint: {
          'circle-color': config.COLOR_OVER_LIMIT,
          'circle-radius': 12,
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2
        }
      },
      // Layer for stations station (text)
      {
        id: 'layer-stations-symbol',
        type: 'symbol',
        source: 'source-stations',
        paint: {
          'text-color': '#fff'
        },
        layout: {
          'text-field': '{reference}',
          'text-offset': [0, 0.6],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-anchor': 'bottom'
        }
      },
      // Layer for the rain path
      {
        id: 'layer-rainpath',
        type: 'line',
        source: 'source-rainpath',
        filter: ['!=', 'name', 'rainpath-head'],
        paint: {
          'line-color': config.RAIN_PATH_COLOR,
          // 'line-width': config.RAIN_PATH_LINE_WIDTH // config.RAIN_PATH_LINE_WIDTH
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            // At minimum zoom, make the rainpath slim.
            8, config.RAIN_PATH_LINE_MIN_WIDTH,
            // At maximum zoom, make the rainpath thick.
            15, config.RAIN_PATH_LINE_MAX_WIDTH
          ]
        }
      },
      {
        id: 'layer-rainpath-head',
        type: 'fill',
        source: 'source-rainpath',
        filter: ['==', 'name', 'rainpath-head'],
        paint: {
          'fill-color': config.RAIN_PATH_COLOR,
          'fill-opacity': 1
        }
      },
      {
        id: 'layer-rainpath-symbol',
        type: 'symbol',
        source: 'source-rainpath',
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#000',
          'text-halo-width': 2
        },
        layout: {
          'text-field': '{title}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-anchor': 'bottom'
        }
      }
    ],
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
  }
}

export const getStationPopupContent = (
  { stationId, windDirection, airTemperature, rainIntensity }: WeatherStationData,
  displayWheatherStationDialogButton: boolean
) => {
  let content = `
    <div>
      <span class="icon-ui-rain"></span>
      <span class="rain">${rainIntensity}</span>
      <span class="unit">mm/h</span>
    </div>
    <div>
      <span class="icon-wind-${windDirection}"></span>
      <span class="temperature">${airTemperature}</span>
      <span class="unit">°C</span>
    </div>
  `

  if (displayWheatherStationDialogButton) {
    content += `
      <div class="flex justify-center m-2">
        <button id="open-wheather-station-dialog-${stationId}" class="Button button-ui Button--contained">
        Show charts
        </button>
      </div>
    `
  }

  return content
}

export function getWindArrow (
  center: [number, number],
  direction: number,
  distance: number,
  properties?: {}
): Feature<MultiLineString> {
  const { geometry: { coordinates } } = destination(point(center), distance, direction)
  const { geometry: { coordinates: endCoords } } = destination(point(center), distance, direction - 180)
  return multiLineString([[coordinates, endCoords]], properties)
}

export function drawArrowHead (center, bearing: number, distance: number, properties = {}): Feature<Polygon> {
  // head of the arrow is in the opposite direction of the "wing" side of the arrow
  const { geometry: { coordinates } } = destination(point(center), distance, bearing - 180)
  const [left, right] = getArrowheadWinds(coordinates, bearing, 0.15) // size of the head is in km by default

  const hatCoords = [[
    left,
    coordinates,
    right,
    left
  ]]
  return polygon(hatCoords, properties)
}
